var exports = {};
!function (e) {
  exports = e();
}(function () {
  return function e(n, t, o) {
    function r(c, u) {
      if (!t[c]) {
        if (!n[c]) {
          var s = null;
          if (!u && s) return s(c, !0);
          if (i) return i(c, !0);
          var a = new Error("Cannot find module '" + c + "'");
          throw a.code = "MODULE_NOT_FOUND", a;
        }

        var l = t[c] = {
          exports: {}
        };
        n[c][0].call(l.exports, function (e) {
          return r(n[c][1][e] || e);
        }, l, l.exports, e, n, t, o);
      }

      return t[c].exports;
    }

    for (var i = null, c = 0; c < o.length; c++) r(o[c]);

    return r;
  }({
    1: [function (e, n, t) {
      "use strict";

      Object.defineProperty(t, "__esModule", {
        value: !0
      }), t.create = t.visible = void 0;

      var o = function (e) {
        var n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
            t = document.createElement("div");
        return t.innerHTML = e.trim(), !0 === n ? t.children : t.firstChild;
      },
          r = function (e, n) {
        var t = e.children;
        return 1 === t.length && t[0].tagName === n;
      },
          i = function (e) {
        return null != (e = e || document.querySelector(".basicLightbox")) && !0 === e.ownerDocument.body.contains(e);
      };

      t.visible = i;

      t.create = function (e, n) {
        var t = function (e, n) {
          var t = o("\n\t\t<div class=\"basicLightbox ".concat(n.className, "\">\n\t\t\t<div class=\"basicLightbox__placeholder\" role=\"dialog\"></div>\n\t\t</div>\n\t")),
              i = t.querySelector(".basicLightbox__placeholder");
          e.forEach(function (e) {
            return i.appendChild(e);
          });
          var c = r(i, "IMG"),
              u = r(i, "VIDEO"),
              s = r(i, "IFRAME");
          return !0 === c && t.classList.add("basicLightbox--img"), !0 === u && t.classList.add("basicLightbox--video"), !0 === s && t.classList.add("basicLightbox--iframe"), t;
        }(e = function (e) {
          var n = "string" == typeof e,
              t = e instanceof HTMLElement == 1;
          if (!1 === n && !1 === t) throw new Error("Content must be a DOM element/node or string");
          return !0 === n ? Array.from(o(e, !0)) : "TEMPLATE" === e.tagName ? [e.content.cloneNode(!0)] : Array.from(e.children);
        }(e), n = function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
          if (null == (e = Object.assign({}, e)).closable && (e.closable = !0), null == e.className && (e.className = ""), null == e.onShow && (e.onShow = function () {}), null == e.onClose && (e.onClose = function () {}), "boolean" != typeof e.closable) throw new Error("Property `closable` must be a boolean");
          if ("string" != typeof e.className) throw new Error("Property `className` must be a string");
          if ("function" != typeof e.onShow) throw new Error("Property `onShow` must be a function");
          if ("function" != typeof e.onClose) throw new Error("Property `onClose` must be a function");
          return e;
        }(n)),
            c = function (e) {
          return !1 !== n.onClose(u) && function (e, n) {
            return e.classList.remove("basicLightbox--visible"), setTimeout(function () {
              return !1 === i(e) || e.parentElement.removeChild(e), n();
            }, 410), !0;
          }(t, function () {
            if ("function" == typeof e) return e(u);
          });
        };

        !0 === n.closable && t.addEventListener("click", function (e) {
          e.target === t && c();
        });
        var u = {
          element: function () {
            return t;
          },
          visible: function () {
            return i(t);
          },
          show: function (e) {
            return !1 !== n.onShow(u) && function (e, n) {
              return document.body.appendChild(e), setTimeout(function () {
                requestAnimationFrame(function () {
                  return e.classList.add("basicLightbox--visible"), n();
                });
              }, 10), !0;
            }(t, function () {
              if ("function" == typeof e) return e(u);
            });
          },
          close: c
        };
        return u;
      };
    }, {}]
  }, {}, [1])(1);
});
export default exports;